import { RouteObject, createBrowserRouter } from "react-router-dom";
import Root from "../pages/root";
import { WEB_TYPE } from "../utils/constants";
import EmployeeSurveyPage from "../pages/employee-survey";
import ChatPage from "../pages/chat";
import CustomerSurveyPage from "../pages/customer-survey";
import EmployeeBenefitsPage from "../pages/employee-benefits";
import ClubprintempsPage from "../pages/clubprintemps";
import LoginPage from "../pages/login";
import LoginSuccessPage from "../pages/login-success";
import ChatBasePage from "../pages/chatbase";
import IFlyWaiverPage from "../pages/dohaquest/ifly-waiver";
import CustomerSurveyPadelPage from "../pages/customer-survey-padel";
import EmployeeWhistleBlowerPage from "../pages/employee-whistle-blower";
import LaserOasisWaiverPage from "../pages/dohaquest/laser-oasis-waiver";
import EmployeeSurveyManagementPage from "../pages/employee-survey-management";
import { RouterObjectExtended } from "../types";
import ErrorPage from "../pages/error";
import Raffle974Page from "../pages/dohaoasis/974raffle";

const routes_private: RouterObjectExtended[] = [
  {
    path: "/error",
    element: <ErrorPage />,
  },
  {
    path: "/employee",
    element: <EmployeeSurveyPage />,
  },
  {
    path: "/employee/whistle-blower",
    element: <EmployeeWhistleBlowerPage />,
  },
  {
    path: "/chat",
    element: <ChatBasePage />,
  },
  {
    path: "/chat/old",
    element: <ChatPage />,
  },
  {
    path: "/survey/retail",
    element: <CustomerSurveyPage type="retail" />,
  },
  { path: "/login", element: <LoginPage /> },
  { path: "/login-success", element: <LoginSuccessPage /> },
];
const routes_public: RouterObjectExtended[] = [
  {
    path: "/error",
    element: <ErrorPage />,
  },
  {
    path: "/survey/fnb",
    element: <CustomerSurveyPage type="fnb" />,
  },
  {
    path: "/survey/padel",
    element: <CustomerSurveyPadelPage />,
  },
  {
    path: "/employee-benefits",
    element: <EmployeeBenefitsPage />,
  },
  {
    path: "/clubprintemps",
    element: <ClubprintempsPage />,
  },
  {
    path: "/dohaquest",
    children: [
      { path: "ifly-waiver", element: <IFlyWaiverPage /> },
      { path: "laser-oasis-waiver", element: <LaserOasisWaiverPage /> },
    ],
  },
  {
    path: "/dohaoasis",
    children: [{ path: "raffle-974", element: <Raffle974Page /> }],
  },
  { path: "/mgmt", element: <EmployeeSurveyManagementPage /> },
];

const routesObject: Record<string, RouteObject[]> = {
  default: [...routes_private, ...routes_public],
  public: routes_public,
  private: routes_private,
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: routesObject[WEB_TYPE],
  },
]);

export default router;
